@font-face {
  font-family: 'Readex Pro';
  src:
    url('./ReadexPro-Bold.woff2') format('woff2'),
    url('./ReadexPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Readex Pro';
  src:
    url('./ReadexPro-SemiBold.woff2') format('woff2'),
    url('./ReadexPro-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Readex Pro';
  src:
    url('./ReadexPro-Medium.woff2') format('woff2'),
    url('./ReadexPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Readex Pro';
  src:
    url('./ReadexPro-Regular.woff2') format('woff2'),
    url('./ReadexPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Readex Pro';
  src:
    url('./ReadexPro-Light.woff2') format('woff2'),
    url('./ReadexPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Readex Pro';
  src:
    url('./ReadexPro-ExtraLight.woff2') format('woff2'),
    url('./ReadexPro-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Afacad';
  src:
    url('fonts/Afacad-Bold.woff2') format('woff2'),
    url('fonts/Afacad-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
