* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  /* 10px */
  font-size: 62.5%;
}

body {
  font-family: 'Readex Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 200;
}

a {
  text-decoration: none;
  color: inherit;
}

.MuiContainer-root {
  max-width: calc(100% - 32px) !important;
}

/* tablet large */
@media screen and (max-width: 1199px) {
  .MuiContainer-root {
    max-width: 100% !important;
  }
}

&::-webkit-scrollbar {
  width: 8px;
  height: 0;
}

&::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 10px;
}

&::-webkit-scrollbar-track {
  background: #e6e6e6;
}
